<template>
  <div class="ykzp clearfix">
    <!-- 口内照 -->
    <div class="zhaopscleft">
      <div class="bitian">
        <!-- <p class="textp1">资料照片:<span class="textp2">*</span><span class="textp3">请至少上传一张图片</span></p> -->
      </div>

      <div class="title lefttitle">口内照</div>

      <ul class="zhapnav clearfix">
        <!-- 正面像 -->
        <li v-for="(item, i) in photographL" :key="i" :class="i === 3 ? 'marginR' : ''">
          <div @click="
            () => {
              index = i;
            }
          ">
            <el-upload class="avatar-uploader" :action="baseURL" :show-file-list="false" :multiple="false"
              :on-change="addFile" :auto-upload="false" accept="image/jpeg,image/jpg,image/png" :with-credentials="true"
              :data="{}">
              <img v-if="item.imageUrl" :ref="`img${i}`" :src="item.imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span v-if="item.add == 'N'" class="bottom">
              <img class="myCursor" @click="rotate.call(this, i)" style="position: absolute; left: 10px; top: 10px"
                :src="anew2" />
              <img class="myCursor" @click="delete1.call(this, i)" style="position: absolute; right: 10px; top: 10px"
                :src="anew1" />
            </span>
          </div>
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </div>
    <!-- X光片 -->
    <div class="zhaopscright">
      <div class="title righttitle">X光片</div>
      <ul class="zhapnav clearfix">
        <li v-for="(item, i) in photographR" :key="i">
          <div @click="
            () => {
              index = i + 8;
            }
          ">
            <el-upload class="avatar-uploader" :action="baseURL" :show-file-list="false" :multiple="false"
              :on-change="addFile" :auto-upload="false" accept="image/jpeg,image/jpg,image/png" :with-credentials="true"
              :data="{}">
              <img v-if="item.imageUrl" :ref="`img${i + 8}`" :src="item.imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>

            <span v-if="item.add == 'N'" class="bottom">
              <img class="myCursor" @click="rotate.call(this, i + 8)" style="position: absolute; left: 10px; top: 10px"
                :src="anew2" />
              <img class="myCursor" @click="delete1.call(this, i + 8)"
                style="position: absolute; right: 10px; top: 10px" :src="anew1" />
            </span>
          </div>
          <p>{{ item.name }}</p>
        </li>
      </ul>
      <div class="upload-box">
        <p class="textp1">
          口扫文件:<span class="textp3">文件限制：最大100M，支持rar.zip格式</span>
        </p>

        <div class="wenjscbox">
          <el-upload class="upload-demo" action="{baseURL}" multiple accept=".zip,.rar" ref="schuanwj"
            :on-change="handleSuccessRar" :auto-upload="false" :on-remove="remove" :limit="1" :file-list="fileList"
            :data="{
              caseId: this.$store.state.binglId,
              stageName: this.$store.state.stageName,
              stageCount: this.$store.state.stageCount,
              fileNumber: '12',
              add: this.scwjadd
            }" :headers="myHeaders">
            <img class="scimg" :src="flagRar ? OK : NO" @scimgDel="scimgDel" />
            <div class="el-upload__text" v-show="flagRar">点击上传扫口文件</div>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* 

 */
import { compress } from '@/util/validate';
import OSS from 'ali-oss';

export default {
  data() {
    return {
        token:{},
      // 是否是新建,true不是,false是
      isNew: true,
      index: 0, //确定现在选中的是哪一张图片;
      baseURL: null, // 默认地址
      anew1: require('@/assets/3新建病例/PC-牙况照片-删除.png'),
      anew2: require('@/assets/3新建病例/PC-牙况照片-旋转.png'),
      photographL: [
        //左侧图片
        {
          name: '正面像',
          add: 'Y',
          imageUrl: require('@/assets/3新建病例/牙况照片/zhengmian.png'),
          imageUrl1: require('@/assets/3新建病例/牙况照片/zhengmian.png'),

        },
        {
          name: '微笑像',
          add: 'Y',
          imageUrl: require('@/assets/3新建病例/牙况照片/weixiao.png'),
          imageUrl1: require('@/assets/3新建病例/牙况照片/weixiao.png'),
        },
        {
          name: '侧面像',
          add: 'Y',
          imageUrl: require('@/assets/3新建病例/牙况照片/cemian.png'),
          imageUrl1: require('@/assets/3新建病例/牙况照片/cemian.png'),

        },
        {
          name: '上牙列面',
          add: 'Y',
          imageUrl: require('@/assets/3新建病例/牙况照片/shanghe.png'),
          imageUrl1: require('@/assets/3新建病例/牙况照片/shanghe.png'),

        },
        {
          name: '下牙列面',
          add: 'Y',
          imageUrl: require('@/assets/3新建病例/牙况照片/xiahe.png'),
          imageUrl1: require('@/assets/3新建病例/牙况照片/xiahe.png'),


        },
        {
          name: '左侧咬合',
          add: 'Y',
          imageUrl: require('@/assets/3新建病例/牙况照片/youce.png'),
          imageUrl1: require('@/assets/3新建病例/牙况照片/youce.png'),

        },
        {
          name: '正面咬合',
          add: 'Y',
          imageUrl: require('@/assets/3新建病例/牙况照片/zhengyaohe.png'),
          imageUrl1: require('@/assets/3新建病例/牙况照片/zhengyaohe.png'),

        },
        {
          name: '右侧咬合',
          add: 'Y',
          imageUrl: require('@/assets/3新建病例/牙况照片/youce.png'),
          imageUrl1: require('@/assets/3新建病例/牙况照片/youce.png'),

        }
      ],
      photographR: [
        //右侧图片
        {
          name: '全景照',
          add: 'Y',
          imageUrl: require('@/assets/3新建病例/牙况照片/x.png'),
          imageUrl1: require('@/assets/3新建病例/牙况照片/x.png'),

        },
        {
          name: '头颅正位像',
          add: 'Y',
          imageUrl: require('@/assets/3新建病例/牙况照片/x.png'),
          imageUrl1: require('@/assets/3新建病例/牙况照片/x.png'),

        },
        {
          name: '头颅侧位像',
          add: 'Y',
          imageUrl: require('@/assets/3新建病例/牙况照片/x.png'),
          imageUrl1: require('@/assets/3新建病例/牙况照片/x.png'),

        }
      ],
      // 存放获取到的图片file
      fileList: [],
      // 至少有一个文件上传成功
      wenj: false,
      scwjadd: 'Y',
      loadingInstance: null,
      newImg: false,
      baseURL: null, // 默认地址
      flag: true,
      flagRar: true,
      OK: require('@/assets/3新建病例/kousao.png'),
      NO: require('@/assets/3新建病例/kousao-2.png'),
      type: '',
      myHeaders: {
        Authorization: this.$cookie.get('token')
      }
    };
  },
  created() {
    this.baseURL = this.$http.defaults.baseURL + '/caseInfo/stepEight';
    
  },
  methods: {
    // 删除
    scimgDel() { },
    // 判断文件 是否
    ifTypeRar(file) {
      const fileExt = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
      const isJPG = fileExt == '.rar' || fileExt == '.zip';
      const isLt2M = file.raw.size / 1024 / 1024 < 100;
      if (!isJPG) {
        this.$message.error('上传文件只支持 rar/zip 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传文件最大100MB !');
        return false;
      }
      return true;
    },
    // 改变事件
    handleSuccessRar(file, fileList) {
      if (!this.ifTypeRar(file)) {
        this.fileList = [];
        return;
      }

      this.file = file;
      this.fileList = fileList; //需要上传的文件

      this.scwjadd = 'N'; //是否新建
      if (this.flagRar) {
        this.upLoadFile()
        this.flagRar = false
      }

    },
    upLoadFile() {

var eightFormdata = new FormData();

eightFormdata.append('caseId', this.$store.state.binglId)
this.$http({
    url: '/caseInfo/getCaseInfoById',
    method: 'POST',
    data: eightFormdata

}).then((data1) => {
    let res = JSON.parse(data1.data.data);
    console.log(res.accessKeySecret);

    this.caseNo = res.caseinfoId;
    this.token.accessKeySecret = res.accessKeySecret
    this.token.accessKeyId = res.accessKeyId
    this.token.securityToken = res.securityToken
    this.token.ossAddress = res.ossAddress
    this.token.bucketName = res.bucketName;

    /* if (data.code == 200) {
     
      console.log(data);
     
    } */

    const data = {
        // 上传文件
        file: this.fileList[0].raw,
        // 病例 id
        caseId: this.$store.state.binglId,
        // 病例阶段
        stageName: this.$store.state.stageName,
        // 病例次数
        stageCount: this.$store.state.stageCount,
        // 文件序号
        fileNumber: 12,
        //是否新增
        add: 'Y'
    }
    var client = new OSS({
        region: 'oss-' + this.token.ossAddress, // oss地址
        accessKeyId: this.token.accessKeyId, // 通过阿里云控制台创建的AccessKey ID。
        accessKeySecret: this.token.accessKeySecret, // 通过阿里云控制台创建的AccessKey Secret。
        stsToken: this.token.securityToken,
        bucket: this.token.bucketName, // 仓库名字
        useFetch: true, // 支持上传大于100KB的文件
        // secure:true // 返回的url为https
    });
    let stageName = this.$store.state.stageName;
    let stage = ''
    if (stageName == 1) {
        stage = 'FirstDesign';
    } else if (stageName == 2) {
        stage = 'MidTermRestart';
    } else if (stageName == 3) {
        stage = 'FinalAdjustment';
    } else if (stageName == 4) {
        stage = 'AfterProduction';
    }
    console.log(this.caseNo);
    var fileName = this.caseNo + '/' + stage + '/' + this.$store.state.stageCount + '/SCAN/' + new Date().getTime() + data.file.name.substr(data.file.name.indexOf('.'))
    console.log(fileName);

    client.multipartUpload(fileName, data.file).then(res => {

        console.log(res);


        let params = {
            // 病例 id
            caseId: this.$store.state.binglId + '',
            // 病例阶段
            stageName: this.$store.state.stageName,
            // 病例次数
            stageCount: this.$store.state.stageCount,
            // 文件序号
            fileNumber: '12',
            //是否新增
            add: 'Y',
            ossfilePath: res.name,
            fileName: data.file.name,

        }
        var eightFormdata = new FormData();
        for (var k in params) {
            eightFormdata.append(k, params[k]);
        }
        this.$http({
            url: "/caseInfo/stepEightScan",
            method: "POST",
            data: eightFormdata,
        }).then(({
            data
        }) => {
            this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                this.loadingInstance.close();

            });
            this.$message({
                message: "保存成功上传成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                }
            });

            this.$router.push("/particulars");
            this.$store.commit("indexRemove", 2);
        }).catch(err => {
            this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                this.loadingInstance.close();

            });
            this.$message({ message: '保存失败上传失败', type: 'error', duration: 1500 })
            console.log(err)
        })

    }).catch(err => {
        console.log(err);
    })
})
    .catch((err) => {
        console.log(err);
    });
},
  /*   // 上传文件
    upLoadFile() {
      this.loadingInstance = this.Loading.service({
        text: '正在上传'
      });
      if (!this.file) {
        this.$router.push('/particulars');
        this.$store.commit('indexRemove', 2);
        this.loadingInstance.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.loadingInstance.close();
        });
        return;
      }
      const data = {
        // 上传文件
        file: this.file.raw,
        // 病例 id
        caseId: this.$store.state.binglId,
        // 病例阶段
        stageName: this.$store.state.stageName,
        // 病例次数
        stageCount: this.$store.state.stageCount,
        // 文件序号
        fileNumber: '12',
        //是否新增
        add: 'N'
      };
      console.log(data);
      var eightFormdata = new FormData();
      for (var k in data) {
        eightFormdata.append(k, data[k]);
      }
    
      this.$http({
        url: '/caseInfo/stepEight',
        method: 'POST',
        data: eightFormdata
      })
        .then(({ data }) => {

          this.loadingInstance.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            this.loadingInstance.close();
          });
          this.$message({
            message: '保存成功上传成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
          // this.$router.push('/particulars');
          this.$store.commit('indexRemove', 2);
        })
        .catch((err) => {
          console.log(22222);

          this.loadingInstance.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            this.loadingInstance.close();
          });
          this.$message({ message: '保存失败上传失败', type: 'error', duration: 1500 });
          console.log(err);
        });
    }, */
    // 删除文件
    removeFile() {
      this.$http({
        url: '/caseInfo/delPhoto',
        method: 'POST',
        data: {
          caseId: this.$store.state.binglId,
          stageName: this.$store.state.stageName,
          stageCount: this.$store.state.stageCount,
          fileNumber: '12'
        }
      })
        .then(({ data }) => { })
        .catch((err) => {
          console.log(err);
        });
    },
    // 改变事件
    handleSuccess(file, fileList) {
      if (!this.ifType(file)) {
        this.fileList = [];
        return;
      }

      this.file = file;
      this.fileList = fileList; //需要上传的文件
      this.scwjadd = 'N'; //是否新建
      this.flag = false;
    },
    // 删除文件
    remove() {
      this.fileList = [];
      this.removeFile();
      this.flag = true;
      this.flagRar = true
    },
    // 图片转base64
    image2Base64(num, imgDom) {
      this.uploading[num].add = 'N';
      let that = this;
      var img = new Image();
      img.src = imgDom;
      img.setAttribute('crossOrigin', 'Anonymous');
      img.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        that.uploading[num].userImage = canvas.toDataURL('image/png');
      };
    },

    // 判断图片格式和大小是否符合标准
    ifType(file) {
      return new Promise((res, rej) => {
        file = file.raw.type;
        const isJPG = file == 'image/png' || file == 'image/jpg' || file == 'image/jpeg';
        if (!isJPG) {
          this.$message.error('上传头像图片只支持 png/jpg/jpeg 格式!');
          res(false);
        }
        res(true);
      });
    },
    // addfileN 代表 改变改变事件，
    async addFile(file, fileList) {
      console.log(this.$store.state.binglId);
      if (await !this.ifType(file)) return;
      let i = this.index;
      this.fileList[i] = file;
      this.fileList[i].raw = await compress(this.fileList[i].raw);
      this.wenj = true;
      if (i < 8) {
        this.photographL[i].imageUrl = URL.createObjectURL(file.raw);
        this.photographL[i].add = 'N';
      } else {
        this.photographR[i - 8].imageUrl = URL.createObjectURL(file.raw);
        this.photographR[i - 8].add = 'N';
      }
      if (!this.isNew) return;
      this.LoadingOpen();
      await this.newLodImg(this.fileList[i].raw, i);
      this.LoadingClose();
    },
    // =========旋转功能==============\\
    rotate(i) {
      if (this.$refs[`img${i}`][0].className == 'avatar') {
        this.$refs[`img${i}`][0].className = 'xuanzhuang90';
      } else if (this.$refs[`img${i}`][0].className == 'xuanzhuang90') {
        this.$refs[`img${i}`][0].className = 'xuanzhuang180';
      } else if (this.$refs[`img${i}`][0].className == 'xuanzhuang180') {
        this.$refs[`img${i}`][0].className = 'xuanzhuang270';
      } else if (this.$refs[`img${i}`][0].className == 'xuanzhuang270') {
        this.$refs[`img${i}`][0].className = 'avatar';
      }
    },
    // =========删除功能==============\\
    delete1(i) {
      this.fileList[i] = '';
      if (i < 8) {
        this.photographL[i].imageUrl = this.photographL[i].imageUrl1;
        this.photographL[i].add = 'Y';
      } else {
        this.photographR[i - 8].imageUrl = this.photographR[i - 8].imageUrl1;
        this.photographR[i - 8].add = 'Y';
      }
      this.$refs[`img${i}`][0].className='avatar'
      this.$refs[`img${i}`].className = 'avatar';
      for (let k of this.fileList) {
        if (Boolean(k)) {
          this.wenj = true;
          break;
        }
        this.wenj = false;
      }
      if (!this.isNew) return;
      this.deleteimg(i);
    },
    // 删除 图片 接口
    deleteimg(fileNumber) {
      this.$http({
        url: '/caseInfo/delPhoto',
        method: 'POST',
        data: {
          caseId: this.$store.state.binglId,
          stageName: this.$store.state.stageName,
          stageCount: this.$store.state.stageCount,
          fileNumber: fileNumber + 1
        }
      })
        .then(({ data }) => { })
        .catch((err) => {
          console.log(err);
        });
    },
    // 上传图片
    newLodImg(file, fileNumber) {
      return new Promise((resolve, reject) => {
        if (!file) return resolve();
        let unLoadFilf;
        if (file.file) {
          unLoadFilf = file.file.raw;
        } else {
          unLoadFilf = file;
        }
      
     
       console.log(this.fileList);
      const data = {
          // 上传文件
          file: unLoadFilf,
          // 病例 id
          caseId: this.$store.state.binglId,
          // 病例阶段
          stageName: this.$store.state.stageName,
          // 病例次数
          stageCount: this.$store.state.stageCount,
          // 文件序号
          fileNumber: fileNumber + 1,
          //是否新增
          add: 'Y',
          realPath:this.fileList[fileNumber].realPath?this.fileList[fileNumber].realPath:unLoadFilf.name
        };
         
        var eightFormdata = new FormData();
        for (var k in data) {
          eightFormdata.append(k, data[k]);
        }
        this.$http({
          url: '/caseInfo/stepEight',
          method: 'POST',
          data: eightFormdata
        })
          .then(({ data }) => {
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }
  }
};
</script>
<style scoped lang="less">
// 兼容垃圾IE
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .ykzp /deep/.title {
    padding-top: 0px !important;
    line-height: 70px;
    display: inline-block;
  }
}

.ykzp {
  min-height: 682px;
}

.xuanzhuang0 {
  display: block;
  // width: 120px;
  // height: 150px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all 0.5s;
  transform: translate(-50%, -50%) rotate(0deg);
}

.xuanzhuang90 {
  display: block;
  // width: 120px;
  // height: 150px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all 0.5s;
  transform: translate(-50%, -50%) rotate(90deg);
}

.xuanzhuang180 {
  display: block;
  // width: 120px;
  // height: 150px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all 0.5s;
  transform: translate(-50%, -50%) rotate(180deg);
}

.xuanzhuang270 {
  display: block;
  // width: 120px;
  // height: 150px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all 0.5s;
  transform: translate(-50%, -50%) rotate(270deg);
}

.xuanzhuang360 {
  display: block;
  // width: 120px;
  // height: 150px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all 0.5s;
  transform: translate(-50%, -50%) rotate(360deg);
}

/deep/.tup1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/deep/.textp1 {
  //   position: absolute;
  //   right: 10%;
  //   bottom: 13%;
  //   font-size: 16px;
  padding-left: 30px;
  color: #ef878e;
  margin-bottom: 30px;
  margin-top: 40px;

  .textp2 {
    font-weight: 900;
    color: #ff0000;
  }

  .textp3 {
    color: black;
  }
}

/deep/.bitian {
  width: 100%;
}

/deep/.zhaopscleft {
  float: left;
  width: 48%;
  height: 100%;
}

/deep/.zhaopscright {
  float: right;
  width: 40%;
  height: 100%;
  margin-right: 8%;
}

/deep/.title {
  width: 100%;
  height: 70px;
  line-height: 70px;
  margin-left: 30px;
  font-weight: 600;
}

/deep/.zhapnav {
  width: 100%;
  // background: #ccc;
  position: relative;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 30px;

  .shade {
    position: absolute;
  }

  // li:nth-child(-n+3){
  //     margin-bottom:0px;
  // }
  li {
    width: 33%;

    margin-bottom: 13px;

    i {
      display: none;
    }

    >div {
      overflow: hidden;

      &:hover {
        .bottom {
          bottom: 0px;
        }
      }
    }

    div {
      width: 120px;
      height: 150px;
      background: #fff;
      position: relative;
      box-sizing: border-box;
    }

    p {
      width: 120px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .marginR {
    margin-right: 28%;
  }
}

/deep/.butbox1 {
  width: 100px;
  margin: none;
  position: absolute;
  right: 170px;
  bottom: 100px;

  .el-checkbox__label {
    color: #333333;
    font-weight: 400;
  }

  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }

  /deep/.el-checkbox__inner::after {
    width: 4px;
    height: 10px;
    left: 7px;
  }
}

/deep/.butbox2 {
  width: 100px;
  margin: none;
  position: absolute;
  right: 170px;
  bottom: 50px;

  .el-checkbox__label {
    color: #333333;
    font-weight: 400;
  }

  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }

  /deep/.el-checkbox__inner::after {
    width: 4px;
    height: 10px;
    left: 7px;
  }
}

// 上传照片element样式
/deep/.avatar-uploader .el-upload {
  border: 1px solid #1175d2;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: #1175d2;
}

/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/.avatar {
  display: block;
  // width: 120px;
  // height: 150px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #1175d2;
}

.bottom {
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 100%;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  transition: all 0.25s linear;
}

.wenjscbox {
  font-weight: 400;
  width: 500px;
  height: 250px;
  position: relative;
  border: 1px solid #1175d2;
  border-radius: 6px;

  &:hover {
    border-color: #1175d2;
  }

  /deep/.upload-demo .el-upload-list {
    position: absolute;
    left: 50%;
    top: 170px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 200px;

    /deep/.el-icon-close {
      top: -180px;
      right: -150px;
      opacity: 0;
      width: 504px;
      height: 254px;
    }

    .el-upload-list__item-status-label {
      top: -180px;
      right: -150px;
      opacity: 0;
      display: block;

      .el-icon-upload-success {
        width: 504px;
        height: 254px;
      }
    }
  }

  /deep/.el-upload-list {
    text-align: center;
  }

  /deep/.el-upload {
    position: relative;
    width: 500px;
    height: 250px;

    .el-upload-list__item-name {
      margin: 0;
    }

    .scimg {
      position: absolute;
      left: 51%;
      top: 46%;
      transform: translate(-50%, -50%);
    }

    .el-upload__text {
      position: absolute;
      left: 50%;
      top: 70%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      font-size: 16px;
    }
  }
}

/deep/.el-button {
  padding: 0px;
  width: 140px;
  height: 36px;
  border: none;
  font-size: 16px;
}

.upload-box {
  margin-top: 10px;
}
</style>