var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ykzp clearfix"},[_c('div',{staticClass:"zhaopscleft"},[_c('div',{staticClass:"bitian"}),_c('div',{staticClass:"title lefttitle"},[_vm._v("口内照")]),_c('ul',{staticClass:"zhapnav clearfix"},_vm._l((_vm.photographL),function(item,i){return _c('li',{key:i,class:i === 3 ? 'marginR' : ''},[_c('div',{on:{"click":function () {
            _vm.index = i;
          }}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":_vm.baseURL,"show-file-list":false,"multiple":false,"on-change":_vm.addFile,"auto-upload":false,"accept":"image/jpeg,image/jpg,image/png","with-credentials":true,"data":{}}},[(item.imageUrl)?_c('img',{ref:("img" + i),refInFor:true,staticClass:"avatar",attrs:{"src":item.imageUrl}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),(item.add == 'N')?_c('span',{staticClass:"bottom"},[_c('img',{staticClass:"myCursor",staticStyle:{"position":"absolute","left":"10px","top":"10px"},attrs:{"src":_vm.anew2},on:{"click":function($event){return _vm.rotate.call(this, i)}}}),_c('img',{staticClass:"myCursor",staticStyle:{"position":"absolute","right":"10px","top":"10px"},attrs:{"src":_vm.anew1},on:{"click":function($event){return _vm.delete1.call(this, i)}}})]):_vm._e()],1),_c('p',[_vm._v(_vm._s(item.name))])])}),0)]),_c('div',{staticClass:"zhaopscright"},[_c('div',{staticClass:"title righttitle"},[_vm._v("X光片")]),_c('ul',{staticClass:"zhapnav clearfix"},_vm._l((_vm.photographR),function(item,i){return _c('li',{key:i},[_c('div',{on:{"click":function () {
            _vm.index = i + 8;
          }}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":_vm.baseURL,"show-file-list":false,"multiple":false,"on-change":_vm.addFile,"auto-upload":false,"accept":"image/jpeg,image/jpg,image/png","with-credentials":true,"data":{}}},[(item.imageUrl)?_c('img',{ref:("img" + (i + 8)),refInFor:true,staticClass:"avatar",attrs:{"src":item.imageUrl}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),(item.add == 'N')?_c('span',{staticClass:"bottom"},[_c('img',{staticClass:"myCursor",staticStyle:{"position":"absolute","left":"10px","top":"10px"},attrs:{"src":_vm.anew2},on:{"click":function($event){return _vm.rotate.call(this, i + 8)}}}),_c('img',{staticClass:"myCursor",staticStyle:{"position":"absolute","right":"10px","top":"10px"},attrs:{"src":_vm.anew1},on:{"click":function($event){return _vm.delete1.call(this, i + 8)}}})]):_vm._e()],1),_c('p',[_vm._v(_vm._s(item.name))])])}),0),_c('div',{staticClass:"upload-box"},[_vm._m(0),_c('div',{staticClass:"wenjscbox"},[_c('el-upload',{ref:"schuanwj",staticClass:"upload-demo",attrs:{"action":"{baseURL}","multiple":"","accept":".zip,.rar","on-change":_vm.handleSuccessRar,"auto-upload":false,"on-remove":_vm.remove,"limit":1,"file-list":_vm.fileList,"data":{
            caseId: this.$store.state.binglId,
            stageName: this.$store.state.stageName,
            stageCount: this.$store.state.stageCount,
            fileNumber: '12',
            add: this.scwjadd
          },"headers":_vm.myHeaders}},[_c('img',{staticClass:"scimg",attrs:{"src":_vm.flagRar ? _vm.OK : _vm.NO},on:{"scimgDel":_vm.scimgDel}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.flagRar),expression:"flagRar"}],staticClass:"el-upload__text"},[_vm._v("点击上传扫口文件")])])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"textp1"},[_vm._v(" 口扫文件:"),_c('span',{staticClass:"textp3"},[_vm._v("文件限制：最大100M，支持rar.zip格式")])])}]

export { render, staticRenderFns }